<template>
  <v-container fluid class="px-5">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <h1 class="font-weight-medium">MIS</h1>
        <v-btn-toggle
          v-if="role !== 'field_officer'"
          mandatory
          v-model="tab"
          class="ml-auto"
          tile
          color="deep-purple accent-3"
        >
          <v-btn :value="0"> Change Detection </v-btn>
          <v-btn :value="1"> Citizen Complaint </v-btn>
          <v-btn :value="2"> Gov Plot </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <ChangeDetection v-if="tab === 0" ref="tab0" />
    <CitizenComplaint v-else-if="tab === 1" ref="tab1" />
    <Plot v-else ref="tab2" />
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import ChangeDetection from './change-detection/index.vue'
import CitizenComplaint from './citizen-complaint/index.vue'
import Plot from './plot/index.vue'
export default {
  components: { ChangeDetection, CitizenComplaint, Plot },
  data() {
    return {
      tab: 0,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    role() {
      return this.user?.role?.code || 'field_officer'
    },
  },
  beforeRouteEnter(to, _, next) {
    next(vm => {
      if ('tab' in to.params) vm.tab = to.params.tab
      vm.$nextTick(() => {
        if ('filters' in to.params) {
          const component = vm.$refs['tab' + vm.tab]
          component.resetQueryParams()
          component.params = { ...component.params, ...to.params.filters }
          component.getData()
        }
      })
    })
  },
}
</script>
