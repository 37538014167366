<template>
  <v-menu v-model="showFilter" offset-y v-bind="$attrs" left nudge-bottom="10">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small color="primary" icon title="Filter Change Detection" v-bind="attrs" v-on="on">
        <v-icon>{{ isFiltered ? icons.mdiFilter : icons.mdiFilterOutline }}</v-icon>
      </v-btn>
    </template>
    <v-card :width="width">
      <v-card-title>Filter</v-card-title>
      <v-card-text class="pb-0">
        <v-autocomplete
          v-if="params.cdSets"
          clearable
          multiple
          v-model="params.cdSets"
          :items="options.cdSets"
          label="CD Set"
          item-text="name"
          item-value="id"
          dense
          :menuProps="menuProps"
        ></v-autocomplete>

        <v-autocomplete
          clearable
          multiple
          v-model="params.wards"
          :items="wards"
          label="Ward"
          item-text="name"
          item-value="id"
          dense
          :menuProps="menuProps"
        ></v-autocomplete>
        <v-autocomplete
          clearable
          multiple
          v-model="params.plots"
          :items="plots"
          :label="`Plot (${plots.length})`"
          dense
          :menuProps="menuProps"
        >
        </v-autocomplete>
        <v-autocomplete
          clearable
          multiple
          v-model="params.statuses"
          :items="options.cdStatuses"
          label="Status"
          item-text="name"
          item-value="id"
          dense
          :menuProps="menuProps"
        ></v-autocomplete>

        <v-autocomplete
          clearable
          multiple
          v-model="params.classTypes"
          :items="options.cdClassificationTypes"
          label="Change Type"
          item-text="name"
          item-value="id"
          dense
          :menuProps="menuProps"
        ></v-autocomplete>

        <v-autocomplete
          clearable
          multiple
          v-model="params.landTypes"
          :items="['GMA', 'GMK', 'GM', 'Other']"
          label="Land Type"
          dense
          :menuProps="menuProps"
        ></v-autocomplete>

        <v-autocomplete
          clearable
          multiple
          v-model="params.types"
          :items="options.cdTypes"
          label="Type"
          item-text="name"
          item-value="id"
          dense
          :menuProps="menuProps"
        ></v-autocomplete>
        <v-checkbox
          v-if="params.deleted !== undefined && role === 'admin'"
          dense
          v-model="params.deleted"
          label="Show deleted detections"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="
            $emit('on-reset')
            showFilter = false
          "
          color="primary"
          >Reset</v-btn
        >
        <v-btn
          text
          @click="
            $emit('change')
            showFilter = false
          "
          color="primary"
          >Apply</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { mdiFilterOutline, mdiFilter } from '@mdi/js'
import { mapState } from 'vuex'
export default {
  props: {
    params: Object,
    options: Object,
    width: { type: Number, default: 400 },
    menuProps: {
      type: Object,
      default: () => ({
        offsetX: true,
        maxHeight: 250,
        left: true,
      }),
    },
  },
  data() {
    return {
      icons: { mdiFilterOutline, mdiFilter },
      showFilter: false,
    }
  },
  watch: {
    'params.wards'() {
      this.params.plots = []
    },
  },
  computed: {
    ...mapState('category', ['wards']),
    role() {
      return this.$store.state.auth.user?.role?.code
    },
    plots() {
      return this.wards
        .filter(w => this.params.wards.includes(w.id))
        .map(w => w.plots)
        .flat()
    },
    isFiltered() {
      return (
        this.params.cdSets?.length > 0 ||
        this.params.wards?.length > 0 ||
        this.params.plots?.length > 0 ||
        this.params.statuses?.length > 0 ||
        this.params.classTypes?.length > 0 ||
        this.params.types?.length > 0 ||
        this.params.landTypes?.length > 0
      )
    },
  },
}
</script>
