<template>
  <v-row>
    <v-col cols="12">
      <DataTable
        :height="$vuetify.breakpoint.md ? undefined : 'calc(100vh - 260px)'"
        :form="form"
        :table-data="tableData"
        :pagination="pagination"
        :params="params"
        :options="options"
        @on-reset="reset"
        @on-search="getData(1)"
        @on-edit="showDialogForm('edit', $event)"
        @on-create="showDialogForm('create')"
        @on-export="exportData"
        @deleted="getData()"
        @updated="getData()"
        @refreshed="getData()"
        @sorted="handleSort"
      />
    </v-col>
    <v-col cols="12">
      <Pagination
        :length="pagination.lastPage"
        :total="pagination.total"
        :params="params"
        @onPageChange="getData"
        @onPerPageChange="getData(1)"
      />
    </v-col>
  </v-row>
</template>

<script>
import DataTable from './components/DataTable.vue'
import Search from './components/Search.vue'
import Pagination from '@/components/Pagination.vue'
import { getComplaints, exportComplaints } from '@/api/complaint'
import { getWards, getComplaintStatuses } from '@/api/category'
import indexMixin from '@/mixins/crud/index'

export default {
  components: {
    DataTable,
    Search,
    Pagination,
  },

  mixins: [
    indexMixin(
      getComplaints,
      {
        wards: {
          func: getWards,
          params: { select: 'id*name', sortBy: 'id' },
        },
        statuses: {
          func: getComplaintStatuses,
        },
      },
      exportComplaints,
    ),
  ],

  data() {
    return {
      model: 'complaint',
      showDialog: false,
      editing: false,
      defaultParams: {
        page: 1,
        perPage: 20,
        sortBy: 'id:desc',
        with: 'ward:id*name;complaintStatus:id*name;user:id*name*mobileNumber',
        createdIn: null,
        updatedIn: null,
        wards: [],
        statuses: [],
      },
      form: {},
    }
  },
}
</script>
