import request from '@/services/request'

export function getChangeDetectionSets(params) {
  return request({
    url: '/cd-sets',
    method: 'get',
    params,
  })
}

export function getChangeDetectionResults(params) {
  return request({
    url: `/cd-results`,
    method: 'get',
    params,
  })
}

export function uploadChangeDetectionSet(data) {
  return request({
    url: '/cd-sets/upload',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function uploadChangeDetectionResultFiles(id, data) {
  return request({
    url: `/cd-results/${id}/attachments`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function deleteChangeDetectionResultFile(id) {
  return request({
    url: `/cd-results/attachments/${id}`,
    method: 'delete',
  })
}

export function exportResults(params, type) {
  return request({
    url: `/cd-results/export-${type}`,
    method: 'get',
    params,
    responseType: 'blob',
  })
}

export function deleteCdResult(id, remark) {
  return request({
    url: '/cd-results/' + id,
    method: 'delete',
    data: { remark },
  })
}

export function createChangeDetectionResult(cdSetId, data) {
  return request({
    url: '/cd-results',
    method: 'post',
    data: { cdSetId, ...data },
  })
}

export function getReports(resultId, params) {
  return request({
    url: '/cd-results/' + resultId + '/reports',
    method: 'get',
    params,
  })
}

export function getReport(id) {
  return request({
    url: '/cd-reports/' + id,
    method: 'get',
  })
}

export function getAuditTrails(resultId) {
  return request({
    url: '/cd-results/' + resultId + '/audit-trails',
    method: 'get',
  })
}

export function exportReport(reportId) {
  return request({
    url: '/cd-reports/' + reportId + '/download',
    method: 'get',
    responseType: 'blob',
  })
}

export function updateResult(resultId, data) {
  return request({
    url: '/cd-results/' + resultId,
    method: 'put',
    data,
  })
}

export function getReportRemarks(reportId) {
  return request({
    url: '/cd-reports/' + reportId + '/remarks',
    method: 'get',
  })
}

export function createReportRemark(reportId, remark) {
  return request({
    url: '/cd-reports/' + reportId + '/remarks',
    method: 'post',
    data: { remark },
  })
}

export function getDetectionStatuses(resultId) {
  return request({
    url: '/cd-results/' + resultId + '/status',
    method: 'get',
  })
}

export function restore(resultId) {
  return request({
    url: '/cd-results/' + resultId + '/restore',
    method: 'put',
  })
}

export function getResult(resultId) {
  return request({
    url: '/cd-results/' + resultId,
    method: 'get',
  })
}

export function getResultCount() {
  return request({
    url: '/cd-results/count',
    method: 'get',
  })
}
