<template>
  <v-menu v-model="showFilter" offset-y :close-on-content-click="false" left nudge-bottom="10">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small color="primary" icon title="Filter" v-bind="attrs" v-on="on">
        <v-icon>{{ icons.mdiFilterOutline }}</v-icon>
      </v-btn>
    </template>
    <v-card width="350">
      <v-card-title>Filter</v-card-title>
      <v-card-text class="pb-0">
        <v-autocomplete
          clearable
          multiple
          v-model="params.wards"
          :items="options.wards"
          label="Ward"
          item-text="name"
          item-value="id"
          dense
          @change="$emit('change')"
        ></v-autocomplete>

        <v-autocomplete
          clearable
          multiple
          v-model="params.statuses"
          :items="options.statuses"
          label="Status"
          item-text="name"
          item-value="id"
          dense
          @change="$emit('change')"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('on-reset')" color="primary">Reset</v-btn>
        <v-btn text @click="showFilter = false" color="primary">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import DateRangePicker from '@/components/DateRangePicker.vue'
import { mdiFilterOutline } from '@mdi/js'
export default {
  props: ['params', 'options'],
  components: { DateRangePicker },
  data() {
    return {
      icons: { mdiFilterOutline },
      showFilter: false,
    }
  },
}
</script>
<style lang=""></style>
