import request from '@/services/request'

export function getComplaints(params) {
  return request({
    url: '/complaints',
    method: 'get',
    params,
  })
}

export function getComplaint(id) {
  return request({
    url: '/complaints/' + id,
    method: 'get',
  })
}

export function getRemarks(id) {
  return request({
    url: '/complaints/' + id + '/remarks',
    method: 'get',
  })
}

export function createRemark(id, remark) {
  return request({
    url: '/complaints/' + id + '/remarks',
    method: 'post',
    data: { remark },
  })
}

export function updateComplaint(id, data) {
  return request({
    url: '/complaints/' + id,
    method: 'put',
    data,
  })
}

export function exportComplaints(params, type) {
  return request({
    url: `/complaints/export-${type}`,
    method: 'get',
    params,
    responseType: 'blob',
  })
}

export function exportComplaint(id) {
  return request({
    url: '/complaints/' + id + '/download',
    method: 'get',
    responseType: 'blob',
  })
}

export function getLayer(params) {
  return request({
    url: '/complaints/layer',
    method: 'get',
    params,
  })
}

export function identifyComplaint(coordinate, distance) {
  return request({
    url: `/complaints/identify`,
    method: 'post',
    data: {
      coordinate,
      distance,
    },
  })
}

export function uploadComplaintFiles(id, data) {
  return request({
    url: `/complaints/${id}/attachments`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function deleteComplaintFile(id) {
  return request({
    url: `/complaints/attachments/${id}`,
    method: 'delete',
  })
}
