<template>
  <v-menu v-model="showFilter" offset-y v-bind="$attrs" left nudge-bottom="10">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small color="primary" icon title="Filter Change Detection" v-bind="attrs" v-on="on">
        <v-icon>{{ isFiltered ? icons.mdiFilter : icons.mdiFilterOutline }}</v-icon>
      </v-btn>
    </template>
    <v-card :width="width">
      <v-card-title>Filter</v-card-title>
      <v-card-text class="pb-0">
        <v-text-field label="PLOT_NUMBER" dense v-model="params.kide" clearable></v-text-field>
        <v-autocomplete
          clearable
          multiple
          v-model="params.wards"
          :items="options.wards"
          label="WARD_NO"
          item-text="name"
          item-value="id"
          dense
          :menu-props="menuProps"
        ></v-autocomplete>
        <v-select
          clearable
          multiple
          v-model="params.landTypes"
          :items="['GMA', 'GMK', 'GM', 'Other']"
          label="Land_Type"
          dense
          :menu-props="menuProps"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="
            $emit('on-reset')
            showFilter = false
          "
          color="primary"
          >Reset</v-btn
        >
        <v-btn
          text
          @click="
            $emit('change')
            showFilter = false
          "
          color="primary"
          >Apply</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { mdiFilterOutline, mdiFilter } from '@mdi/js'
import DateRangePicker from '../DateRangePicker.vue'
export default {
  props: {
    params: Object,
    options: Object,
    width: { type: Number, default: 400 },
    menuProps: {
      type: Object,
      default: () => ({
        offsetX: true,
        maxHeight: 250,
        left: true,
      }),
    },
  },
  components: { DateRangePicker },
  data() {
    return {
      icons: { mdiFilterOutline, mdiFilter },
      showFilter: false,
    }
  },

  computed: {
    isFiltered() {
      return (
        this.params.wards?.length > 0 ||
        this.params.landTypes?.length > 0 ||
        this.params.kide
      )
    },
  },
}
</script>
