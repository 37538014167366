<template>
  <v-data-table
    :style="{ height }"
    height="calc(100% - 48px)"
    :headers="getHeaders()"
    :items="tableData"
    disable-pagination
    fixed-header
    :options.sync="sortOptions"
    calculate-widths
    hide-default-footer
    :server-items-length="1"
    disable-filtering
  >
    <template v-slot:top>
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-menu offset-y left nudge-bottom="10">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" title="Export" class="ml-1" v-bind="attrs" v-on="on">
              <v-icon>{{ icons.mdiDownload }}</v-icon></v-btn
            >
          </template>
          <v-list dense>
            <v-list-item @click="$emit('on-export', 'csv')">
              <v-list-item-title
                ><v-icon size="18" left>{{ icons.mdiFileExcel }}</v-icon> <span>CSV</span></v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="$emit('on-export', 'pdf')">
              <v-list-item-title
                ><v-icon size="18" left>{{ icons.mdiPdfBox }}</v-icon> <span>PDF</span></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn icon color="primary" @click="$emit('refreshed')">
          <v-icon>{{ icons.mdiRefresh }}</v-icon>
        </v-btn>
        <ComplaintFilter
          :params="params"
          :options="options"
          :close-on-content-click="false"
          nudge-left="12"
          @change="$emit('on-search')"
          @on-reset="$emit('on-reset')"
        />
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn x-small icon color="primary" @click="viewInMap(item.id)">
        <v-icon title="View in map">{{ icons.mdiMap }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:item.createdAt="{ item }">
      <span>{{ item.createdAt | datetime }}</span>
    </template>
    <template v-slot:item.updatedAt="{ item }">
      <span>{{ item.updatedAt | datetime }}</span>
    </template>
  </v-data-table>
</template>
<script>
import dataTableMixin from '@/mixins/crud/data-table'
import { mdiFileExcel, mdiPdfBox, mdiMap, mdiFilterOutline } from '@mdi/js'
import ComplaintFilter from '@/components/complaint/ComplaintFilter.vue'
import EventBus from '@/services/event-bus'
export default {
  components: { ComplaintFilter },
  mixins: [dataTableMixin()],
  data() {
    return { icons: { mdiFileExcel, mdiPdfBox, mdiMap, mdiFilterOutline } }
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: 'Location Detail', value: 'name' },
        { text: 'Ward', value: 'ward.name', sortable: false, align: 'right' },
        { text: 'Status', value: 'complaintStatus.name' },
        { text: 'Citizen', value: 'user.name' },
        { text: 'Created At', value: 'createdAt' },
        { text: 'Updated At', value: 'updatedAt' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false },
      ]
    },
  },
  methods: {
    viewInMap(id) {
      this.$router.push({
        name: 'map-view',
        params: {
          complaintId: id,
          tab: 1,
        },
      })
      EventBus.$emit('view-complaint-in-map', id)
    },
  },
}
</script>
