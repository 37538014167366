import request from '@/services/request'

export function index(params) {
  return request({
    url: '/plots',
    method: 'get',
    params,
  })
}

export function show(id) {
  return request({
    url: '/plots/' + id,
    method: 'get',
  })
}

export function getLayer() {
  return request({
    url: '/plots/layer',
    method: 'get',
  })
}

export function exportPlots(params, type) {
  return request({
    url: `/plots/export-${type}`,
    method: 'get',
    params,
    responseType: 'blob',
  })
}

export function identifyPlot(coordinate, distance) {
  return request({
    url: `/plots/identify`,
    method: 'post',
    data: {
      coordinate,
      distance,
    },
  })
}
