<template>
  <v-data-table
    class="plot-table"
    :style="{ height }"
    height="calc(100% - 48px)"
    :headers="getHeaders()"
    :items="tableData"
    disable-pagination
    fixed-header
    :options.sync="sortOptions"
    calculate-widths
    hide-default-footer
    :server-items-length="1"
    disable-filtering
  >
    <template v-slot:top>
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-menu offset-y left nudge-bottom="10">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" title="Export" class="ml-1" v-bind="attrs" v-on="on">
              <v-icon>{{ icons.mdiDownload }}</v-icon></v-btn
            >
          </template>
          <v-list dense>
            <v-list-item @click="$emit('on-export', 'csv')">
              <v-list-item-title
                ><v-icon size="18" left>{{ icons.mdiFileExcel }}</v-icon> <span>CSV</span></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn icon color="primary" @click="$emit('refreshed')">
          <v-icon>{{ icons.mdiRefresh }}</v-icon>
        </v-btn>
        <PlotFilter
          :params="params"
          :options="options"
          :close-on-content-click="false"
          nudge-left="12"
          @change="$emit('on-search')"
          @on-reset="$emit('on-reset')"
        />
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn x-small icon color="primary" @click="viewInMap(item.id)">
        <v-icon title="View in map">{{ icons.mdiMap }}</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import dataTableMixin from '@/mixins/crud/data-table'
import { mdiFileExcel, mdiPdfBox, mdiMap, mdiFilterOutline } from '@mdi/js'
import PlotFilter from '@/components/plot/PlotFilter.vue'
import EventBus from '@/services/event-bus'
export default {
  components: { PlotFilter },
  mixins: [dataTableMixin()],
  data() {
    return { icons: { mdiFileExcel, mdiPdfBox, mdiMap, mdiFilterOutline } }
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: 'OBJECTID_1', value: 'OBJECTID_1' },
        { text: 'MAPYEAR', value: 'MAPYEAR' },
        { text: 'PLOT_NUMBER', value: 'KIDE' },
        { text: 'Land_Type', value: 'Land_Type' },
        { text: 'RMC', value: 'RMC' },
        { text: 'WARD_NO', value: 'WARD_NO' },
        { text: 'Shape_Leng', value: 'Shape_Leng' },
        { text: 'Shape_Area', value: 'Shape_Area' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false, width: '80' },
      ]
    },
  },
  methods: {
    viewInMap(id) {
      this.$router.push({
        name: 'map-view',
        params: {
          plotId: id,
          tab: 2,
        },
      })
      EventBus.$emit('view-plot-in-map', id)
    },
  },
}
</script>

<style lang="scss">
.plot-table {
  table > tbody > tr > td:nth-child(10),
  table > thead > tr > th:nth-child(10) {
    position: sticky !important;
    position: -webkit-sticky !important;
    right: 0;
  }
  table > thead > tr > th:nth-child(10) {
    z-index: 5;
  }
}
.theme--light.plot-table {
  table > tbody > tr > td:nth-child(10),
  table > thead > tr > th:nth-child(10) {
    background: #fff;
  }
}
.theme--dark.plot-table {
  table > tbody > tr > td:nth-child(10),
  table > thead > tr > th:nth-child(10) {
    background: #312d4b;
  }
}
</style>
